import React from "react"
import {useTranslation} from "react-i18next";

import SEO from "../components/Seo/Seo";
import "../styles/pages/privacy.scss"

const PrivacyPage = ({ location }) => {
    const { i18n, t } = useTranslation();

    return (
        <>
            <SEO
                title={t("navigation.privacy")}
                lang={i18n.language}
                description={t("meta.privacy.description")}
                pathname={location.pathname}
                meta={i18n.language === 'en' ? [] : [{name: 'robots', context: 'noindex,nofollow'}]}
            />
            <div className="Privacy">
                <section>
                    <div className="container text-center">
                        <h1 className="text-white">{t("navigation.privacy")}</h1>
                        <p className="text-white">
                            {t("label.effectiveDate")}: November 1, 2019
                        </p>
                    </div>
                </section>

                <div className="position-relative">
                    <div className="custom-shape-divider-bottom">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path
                                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                                fill="#f1f3f4"></path>
                        </svg>
                    </div>
                </div>

                <section className="bg-light content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <p>Schedulino Limited ("Schedulino", "us", "we", or "our") is an Irish company registered in Ireland under company number 598691 that operates the <a href="https://schedulino.com">schedulino.com</a> and all websites within that domain and the Schedulino mobile application (hereinafter referred to as the "Service").</p>

                                <p>This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

                                <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>

                                <h2>Definitions</h2>
                                <ul>
                                    <li>
                                        <p><strong>Service</strong></p>
                                        <p>Service means the schedulino.com website and the Schedulino mobile application operated by Schedulino Limited</p>
                                    </li>
                                    <li>
                                        <p><strong>Personal Data</strong></p>
                                        <p>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
                                    </li>
                                    <li>
                                        <p><strong>Usage Data</strong></p>
                                        <p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                                    </li>
                                    <li>
                                        <p><strong>Cookies</strong></p>
                                        <p>Cookies are small files stored on your device (computer or mobile device).</p>
                                    </li>
                                    <li>
                                        <p><strong>Data Controller</strong></p>
                                        <p>Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.</p>
                                        <p>For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</p>
                                    </li>
                                    <li>
                                        <p><strong>Data Processors (or Service Providers)</strong></p>
                                        <p>Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.</p>
                                        <p>We may use the services of various Service Providers in order to process your data more effectively.</p>
                                    </li>
                                    <li>
                                        <p><strong>Data Subject (or User)</strong></p>
                                        <p>Data Subject is any living individual who is using our Service and is the subject of Personal Data.</p>
                                    </li>
                                </ul>


                                <h2>Information Collection and Use</h2>
                                <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                                <h3>Types of Data Collected</h3>

                                <h4>Personal Data</h4>
                                <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>

                                <ul>
                                    <li>Email address</li>    <li>First name and last name</li>    <li>Phone number</li>  <li>Company name</li>  <li>Cookies and Usage Data</li>
                                </ul>

                                <p>
                                    Schedulino also collect data provided by you and / or your employer when you sign up for the Service and use any of the associated services by filling in a field or forms in our Service or any of our social media platforms, or by corresponding with us by phone, e-mail or otherwise.
                                    Data provided through the use of the Service, for example employee details, and/or work pattern information or uploaded documents (including photographs) or any other details entered into our Service.
                                </p>

                                <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send or by contacting us.</p>

                                <h4>Usage Data</h4>

                                <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data").</p>
                                <p>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                                <p>When you access the Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>


                                <h4>Tracking & Cookies Data</h4>
                                <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
                                <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse our Service.</p>
                                <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                                <p>Examples of Cookies we use:</p>
                                <ul>
                                    <li><strong>Session Cookies.</strong> We use Session Cookies to operate our Service.</li>
                                    <li><strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.</li>
                                    <li><strong>Security Cookies.</strong> We use Security Cookies for security purposes.</li>
                                </ul>

                                <h2>Use of Data</h2>
                                <p>Schedulino Limited uses the collected data for various purposes:</p>
                                <ul>
                                    <li>To provide and maintain our Service</li>
                                    <li>To notify you about changes to our Service</li>
                                    <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                                    <li>To provide customer support</li>
                                    <li>To gather analysis or valuable information so that we can improve our Service</li>
                                    <li>To monitor the usage of our Service</li>
                                    <li>To detect, prevent and address technical issues</li>
                                    <li>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</li></ul>


                                <h2>Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)</h2>
                                <p>If you are from the European Economic Area (EEA), Schedulino Limited legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>
                                <p>Schedulino Limited may process your Personal Data because:</p>
                                <ul>
                                    <li>We need to perform a contract with you</li>
                                    <li>You have given us permission to do so</li>
                                    <li>The processing is in our legitimate interests and it is not overridden by your rights</li>
                                    <li>For payment processing purposes</li>    <li>To comply with the law</li>
                                </ul>


                                <h2>Retention of Data</h2>
                                <p>Schedulino Limited will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.</p>
                                <p>Schedulino Limited will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.</p>

                                <h2>Transfer of Data</h2>
                                <p>Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
                                <p>If you are located outside Ireland and choose to provide information to us, please note that we transfer the data, including Personal Data, to Ireland and process it there.</p>
                                <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                                <p>Schedulino Limited will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

                                <h2>Disclosure of Data</h2>


                                <h3>Legal Requirements</h3>
                                <p>Schedulino Limited may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                                <ul>
                                    <li>To comply with a legal obligation</li>
                                    <li>To protect and defend the rights or property of Schedulino Limited</li>
                                    <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                                    <li>To protect the personal safety of users of the Service or the public</li>
                                    <li>To protect against legal liability</li>
                                </ul>

                                <h2>Security of Data</h2>
                                <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                                <h2>Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)</h2>
                                <p>We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>
                                <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>

                                <h2>Your Data Protection Rights under the General Data Protection Regulation (GDPR)</h2>
                                <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Schedulino Limited aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.</p>
                                <p>If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
                                <p>In certain circumstances, you have the following data protection rights:</p>
                                <ul>
                                    <li>
                                        <p><strong>The right to access, update or delete the information we have on you.</strong> Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.</p>
                                    </li>
                                    <li>
                                        <p><strong>The right of rectification.</strong> You have the right to have your information rectified if that information is inaccurate or incomplete.</p>
                                    </li>
                                    <li>
                                        <p><strong>The right to object.</strong> You have the right to object to our processing of your Personal Data.</p>
                                    </li>
                                    <li>
                                        <p><strong>The right of restriction.</strong> You have the right to request that we restrict the processing of your personal information.</p>
                                    </li>
                                    <li>
                                        <p><strong>The right to data portability.</strong> You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.</p>
                                    </li>
                                    <li>
                                        <p><strong>The right to withdraw consent.</strong> You also have the right to withdraw your consent at any time where Schedulino Limited relied on your consent to process your personal information.</p>
                                    </li>
                                </ul>
                                <p>Please note that we may ask you to verify your identity before responding to such requests.</p>

                                <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>

                                <h2>Service Providers</h2>
                                <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p>
                                <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                                <h3>Analytics</h3>
                                <p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
                                <ul>
                                    <li>
                                        <p><strong>Google Analytics</strong></p>
                                        <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</p>
                                        <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
                                    </li>
                                    <li>
                                        <p><strong>Hotjar Form Analysis & Conversion Funnels </strong></p>
                                        <p>Hotjar is an analytics service provided by Hotjar Ltd. Hotjar honors generic Do Not Track headers. This means your browser can tell its script not to collect any of your data. This is a setting that is available in all major browsers</p>
                                        <p>For more information on the privacy practices of Hotjar, please visit the Hotjar Privacy web page: <a href="https://www.hotjar.com/privacy">https://www.hotjar.com/privacy</a></p>
                                        <p>To opt-out Hotjar from processing your data, follow these instructions from Hotjar:  <a href="https://www.hotjar.com/legal/compliance/opt-out">https://www.hotjar.com/legal/compliance/opt-out</a></p>
                                    </li>
                                </ul>

                                <h3>Behavioral Remarketing</h3>
                                <p>Schedulino Limited uses remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.</p>
                                <ul>
                                    <li>
                                        <p><strong>Google Ads (AdWords)</strong></p>
                                        <p>Google Ads (AdWords) remarketing service is provided by Google Inc.</p>
                                        <p>You can opt-out of Google Analytics for Display Advertising and customise the Google Display Network ads by visiting the Google Ads Settings page: <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a></p>
                                        <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>
                                        <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
                                    </li>
                                    <li>
                                        <p><strong>Facebook</strong></p>
                                        <p>Facebook remarketing service is provided by Facebook Inc.</p>
                                        <p>You can learn more about interest-based advertising from Facebook by visiting this page: <a href="https://www.facebook.com/help/164968693837950">https://www.facebook.com/help/164968693837950</a></p>
                                        <p>To opt-out from Facebook's interest-based ads, follow these instructions from Facebook:  <a href="https://www.facebook.com/help/568137493302217">https://www.facebook.com/help/568137493302217</a><p>
                                            <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>, the Digital Advertising Alliance of Canada in Canada <a href="http://youradchoices.ca/">http://youradchoices.ca/</a> or the European Interactive Digital Advertising Alliance in Europe <a href="http://www.youronlinechoices.eu/">http://www.youronlinechoices.eu/</a>, or opt-out using your mobile device settings.</p>
                                            <p>For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: <a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a></p></p></p>
                                    </li>
                                    <li> <p><strong>Twitter</strong></p> <p>Twitter remarketing service is provided by Twitter Inc.</p> <p>You can opt-out from Twitter's interest-based ads by following their instructions: <a href="https://support.twitter.com/articles/20170405">https://support.twitter.com/articles/20170405</a></p> <p>You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: <a href="https://twitter.com/privacy">https://twitter.com/privacy</a></p> </li>
                                    <li> <p><strong>LinkedIn</strong></p> <p>LinkedIn remarketing service is provided by LinkedIn Inc.</p> <p>You can learn more about interest-based advertising from LinkedIn by visiting this page: <a href="https://www.linkedin.com/legal/ads-policy">https://www.linkedin.com/legal/ads-policy</a></p> <p>To opt-out from LinkedIn's interest-based ads, follow these instructions from LinkedIn: <a href="https://www.linkedin.com/help/linkedin/answer/62931/manage-advertising-preferences">https://www.linkedin.com/help/linkedin/answer/62931/manage-advertising-preferences</a></p><p> </p><p>For more information on the privacy practices of LinkedIn, please visit LinkedIn's Privacy Policy: <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a></p> </li>
                                </ul>

                                <h3>Heat Mapping and Session Recording</h3>
                                <p>Schedulino Limited uses heat mapping services that are used to display the areas of a page where Users most frequently move the mouse or click. This shows where the points of interest are. These services make it possible to monitor and analyze web traffic and keep track of User behavior.</p>
                                <p>Some of these services may record sessions and make them available for later visual playback.</p>
                                <ul>
                                    <li>
                                        <p><strong>Hotjar Heat Maps & Recordings</strong></p>
                                        <p>Hotjar is a session recording and heat mapping service provided by Hotjar Ltd.</p>
                                        <p>Their Privacy Policy can be viewed at <a href="https://www.hotjar.com/privacy">https://www.hotjar.com/privacy</a></p>
                                        <p>To opt-out Hotjar from processing your data, follow these instructions from Hotjar:  <a href="https://www.hotjar.com/legal/compliance/opt-out">https://www.hotjar.com/legal/compliance/opt-out</a></p>
                                    </li>
                                </ul>

                                <h3>Communication</h3>
                                <ul>
                                    <li>
                                        <p><strong>Intercom Inc.</strong></p>
                                        <p>Intercom is a User database management service provided by Intercom Inc. Intercom can also be used as a medium for communications, either through email, or through messages within this Application.</p>
                                        <p>Their Privacy Policy & Terms can be viewed at <a href="https://www.intercom.com/terms-and-policies#privacy">https://www.intercom.com/terms-and-policies</a></p>
                                    </li>

                                    {/*<li>*/}
                                    {/*    <p><strong>Mailchimp widget (The Rocket Science Group LLC)</strong></p>*/}
                                    {/*    <p>The Mailchimp widget is a service for interacting with the Mailchimp email address management and message sending service provided by The Rocket Science Group LLC.</p>*/}
                                    {/*    <p>Their Privacy Policy can be viewed at <a href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a></p>*/}
                                    {/*</li>*/}
                                </ul>

                                <h3>Payments</h3>
                                <p>We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
                                <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American
                                    Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
                                <p>The payment processors we work with are:</p>
                                <ul>
                                    <li>
                                        <p><strong>Stripe</strong></p>
                                        <p>Their Privacy Policy can be viewed at <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a></p>
                                    </li>
                                </ul>

                                <h2>Links to Other Sites</h2>
                                <p>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>


                                <h2>Children's Privacy</h2>
                                <p>Our Service does not address anyone under the age of 18 ("Children").</p>
                                <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>


                                <h2>Changes to This Privacy Policy</h2>
                                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                                <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
                                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>


                                <h2>Contact Us</h2>
                                <p>If you have any questions about this Privacy Policy, please contact us:</p>
                                <ul>
                                    <li>By email: <a href="mailto:support@schedulino.com">support@schedulino.com</a></li>
                                    <li>By visiting this page on our website: <a href="https://schedulino.com/contact/">https://schedulino.com/contact/</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
};

export default PrivacyPage;
